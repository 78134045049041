const _ = require("lodash");

const theme = {
  size: {
    sm: "16px",
    md: "30px",
    lg: "30px",
    xlg: "38px",
  },
  color: {
    white: "#ffffff",
    torquoise: "rgb(0,174,204)",
    background: "#17202A",
  },
  breakpoint: {
    sm: "768px",
    md: "992px",
    lg: "1200px",
  },
};

const getTheme = (originTheme = {}) => _.merge({}, theme, originTheme);

module.exports = getTheme;
