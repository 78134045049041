//
// ONLY EDIT THIS FILE IN /frontend/src/
//

const translation = {
  home: {
    headline: {
      part1: "match",
      part2: "my",
      part3: "estate",
    },
    subheadline: "MAKING REAL ESTATE TRANSACTIONS PROFESSIONAL",
    text: "Mit unserem intelligenten Matching werden Immobilien diskret passenden Investoren vorgeschlagen. Mit unserem neuartig entwickeltem System, schaffen wir eine 360-Grad-Lösung für digitale Immobilientransaktionen.",
    iconTextDE: "Wir arbeiten derzeit an unserer Webpräsenz",
    iconTextEN: "We are currently working on our website",
  },
};

module.exports = {
  translation,
  langKey: "DE",
};
