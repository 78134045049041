import styled from "styled-components";

export const StyledImage = styled.img`
  height: ${({ height }) => height || "auto"};
  width: ${({ width }) => width || "auto"};

  max-height: ${({ maxHeight }) => maxHeight || "auto"};
  max-width: ${({ maxWidth }) => maxWidth || "auto"};

  margin: ${({ margin }) => margin || "0px"};
`;
