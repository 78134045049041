import styled from "styled-components";
import Text from "../UI/Text/Text";
import Image from "../UI/Image/Image";

export const StyledHome = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

export const Content = styled.div`
  text-align: center;
`;

export const Headline = styled.div`
  display: inline-block;
  position: relative;
  * {
    font-size: 55px;
    font-family: "highlight";
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    * {
      font-size: 45px;
    }
  }
`;
export const Highlight = styled.span`
  color: ${({ theme }) => theme.color.torquoise};
`;
export const Trademark = styled.div`
  font-size: 25px;
  right: -15px;
  top: -7.5px;
  position: absolute;
`;

export const Subheadline = styled(Text)`
  color: ${({ theme }) => theme.color.torquoise};
  text-align: center;
  margin-top: 25px;
  font-family: "highlight";
`;

export const Icon = styled(Image)`
  height: 125px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 75px;
  }
`;
