const de = require("../shared-resources/translations/de");
const theme = require("../shared-resources/theme");

const defaultState = {
  translation: de.translation,
  currentLang: de.langKey,
  theme,
};

export default defaultState;
