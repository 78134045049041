import { StyledText } from "./Text.Styled";

const Text = ({
  text,
  size,
  color,
  align,
  font,
  margin,
  className,
  width,
  onClick,
}) => {
  return (
    <StyledText
      size={size}
      color={color}
      align={align}
      font={font}
      margin={margin}
      className={className}
      width={width}
      onClick={onClick}
    >
      {text}
    </StyledText>
  );
};

export default Text;
