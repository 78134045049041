import { StyledImage } from "./Image.Styled";

const Image = ({
  src,
  height,
  width,
  maxHeight,
  maxWidth,
  className,
  margin,
  onClick,
}) => {
  return (
    <StyledImage
      src={src}
      height={height}
      width={width}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
      className={className}
      margin={margin}
      onClick={onClick}
    />
  );
};

export default Image;
