import { useSelector } from "react-redux";
import {
  StyledHome,
  Content,
  Headline,
  Highlight,
  Subheadline,
  Trademark,
  Icon,
} from "./Home.Styled";
import Container from "../UI/Container/Container";
import Text from "../UI/Text/Text";
import screenIcon from "../../assets/image/screenIcon.png";

const Home = () => {
  const translation = useSelector((s) => s.translation);
  return (
    <StyledHome>
      <Container>
        <Content>
          <Headline>
            <span>{translation.home.headline.part1}</span>
            <Highlight>{translation.home.headline.part2}</Highlight>
            <span>{translation.home.headline.part3}</span>
            <Trademark>&reg;</Trademark>
          </Headline>
          <Subheadline text={translation.home.subheadline} />
          <Text margin="75px 0" align={"center"} text={translation.home.text} />
          <Icon margin="50px 0 0 0" src={screenIcon} height={"125px"} />
          <Text align={"center"} text={translation.home.iconTextDE} />
          <Text align={"center"} text={translation.home.iconTextEN} />
        </Content>
      </Container>
    </StyledHome>
  );
};

export default Home;
