import AvenirBook from "./assets/font/avenir_ff/AvenirLTStd-Book.otf";
import MontserratRegular from "./assets/font/Montserrat/static/Montserrat-Regular.ttf";
import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`

@font-face {
  font-family: "highlight";
  font-weight: thin;
  src: url(${AvenirBook}) format("opentype");
}

@font-face {
  font-family: "regular";
  src: url(${MontserratRegular}) format("truetype");
}

* {
  padding: 0px;
  margin: 0px;
  font-family: "regular", sans-serif;
  font-size: ${({ theme }) => theme.size.md};
  box-sizing: border-box;
  color: ${({ theme }) => theme.color.white};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.size.sm};
  }
}

html,
body {
  scroll-behavior: smooth;
  background-color: ${({ theme }) => theme.color.background};
}

.Toastify {
  * {
  font-size: ${({ theme }) => theme.size.sm};
  }
}

.main-content {
  min-height: 100vh;
}

`;

export default GlobalStyles;
