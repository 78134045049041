import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import GlobalStyle from "./Global.Styled";
import { ThemeProvider } from "styled-components";
import getTheme from "./shared-resources/theme";
import Home from "./components/Home/Home";

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={getTheme()}>
        <GlobalStyle />
        <Router>
          <Routes>
            <Route index element={<Home />} />
            <Route path={"*"} element={<Navigate replace to="/" />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
